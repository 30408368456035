import Button from 'components/dist/atoms/Button';
import Dialog from 'components/dist/atoms/Dialog';
import Icon from 'components/dist/atoms/Icon';
import Label from 'components/dist/atoms/Label';
import Separator from 'components/dist/atoms/Separator';
import Stack from 'components/dist/atoms/Stack';
import Text from 'components/dist/atoms/Text';
import { FormikedAddressField } from 'src/components/common/form/formiked-fields/formiked-address-field';

import { useVenturesNoPrimaryRealEstateAddressState } from './ventures-no-primary-real-estate-address.hook';
import { VenturesNoPrimaryRealEstateAddressProps } from './ventures-no-primary-real-estate-address.types';


export const VenturesNoPrimaryRealEstateAddress = (props: VenturesNoPrimaryRealEstateAddressProps) => {
    const state = useVenturesNoPrimaryRealEstateAddressState(props);

    return <form
        onSubmit={state.formik.handleSubmit}
        className='flex-1 overflow-hidden flex flex-col gap-3'>
        <Stack
            space="md"
            className='p-4'
            row>
            <Icon name="BigExclamation" width={25} height={25} className="shrink-0 text-black-10" />
            <Stack space="sm">
                <Text>There’s no address associated with your real estate primary collateral “<Text as='span' weight="medium">{props.collateral?.sherpaEntity.name}</Text>”. Add address to continue</Text>
            </Stack>
        </Stack>
        <Separator />
        <Stack space="md" className='py-2 px-6 items-start overflow-hidden flex-1 overflow-auto scrollbar-stable'>
            <Label
                variant={!!state.formik.errors.streetAddress ? 'destructive' : "secondary"}
                htmlFor="clientAddress" className='w-full'>
                {props.collateral.sherpaEntity.name} Address
            </Label>
            <div className='grid grid-cols-1 sm:grid-cols-4 gap-4 sm:[&>_div:nth-child(1)]:col-span-4 sm:[&>_div:nth-child(2)]:col-span-4 sm:[&>_div:nth-child(3)]:col-span-2 sm:[&>_div:nth-child(4)]:col-span-1 sm:[&>_div:nth-child(5)]:col-span-1 sm:[&>_div]:max-w-full w-full'>
                <FormikedAddressField
                    size='small'
                    label={`${props.collateral.sherpaEntity.name} Address`}
                    name="streetAddress"
                    error={!!state.formik.errors.streetAddress}
                    formik={state.formik}
                    placeholder={`${props.collateral.sherpaEntity.name} Address`} />
            </div>
        </Stack>
        <Separator />
        <Stack space="sm" row className='justify-end pb-4 px-4 shrink-0'>
            <Dialog.Close asChild>
                <Button
                    disabled={state.formik.isSubmitting}
                    variant="outline">
                    Cancel
                </Button>
            </Dialog.Close>
            <Button
                className='min-w-20'
                loading={state.formik.isSubmitting}
                type='submit'>
                Add
            </Button>
        </Stack>
    </form>
}