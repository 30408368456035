import Icon from 'components/dist/atoms/Icon';
import Stack from 'components/dist/atoms/Stack';
import Text from 'components/dist/atoms/Text';
import type { AppUserDTO2, VentureExportStatus } from 'src/backend';
import { useUser } from 'src/hooks/use-user';
import { getUserDisplayName } from 'src/utils/user/get-user-display-name';

interface VenturesExportStatusProps {
    exportStatus: VentureExportStatus;
    lastExportTime: string;
    ventureExportedBy: AppUserDTO2;
    ventureLoanLogNumber: string;
    ventureLoanUrl: string;
}

export const VenturesExportStatus = (props: VenturesExportStatusProps) => {
    const { user } = useUser();
    const lastExportedName = user?.id === props.ventureExportedBy?.id
        ? "Me"
        : getUserDisplayName(props.ventureExportedBy);

    return (<Stack space="sm" className='pointer-events-none items-start' onClick={(e) => {
        e.stopPropagation();
    }}>
        <Text
            size='xs'
            as="div"
            className="flex items-center gap-2 "
            variant={(() => {
                switch (props.exportStatus) {
                    case 'EXPORTING':
                        return 'primary';
                    case 'EXPORT_ERROR':
                        return 'destructive';
                    case 'EXPORT_COMPLETE':
                        return 'success';
                    case 'EXPORT_INCOMPLETE':
                        return 'warning';
                    default:
                        return 'inherit';
                }
            })()}
        >
            <Icon
                name={(() => {
                    switch (props.exportStatus) {
                        case 'EXPORTING':
                            return 'Refresh';
                        case 'EXPORT_ERROR':
                            return 'InfoEmpty';
                        case 'EXPORT_COMPLETE':
                            return 'CheckCircle';
                        case 'EXPORT_INCOMPLETE':
                            return 'InfoEmpty';
                        default:
                            return 'InfoEmpty';
                    }
                })()}
                width={16}
                height={16}
                strokeWidth={1.5}
            />
            {!props.lastExportTime && "Not exported"}
            {props.lastExportTime &&
                props.exportStatus !== 'EXPORT_ERROR'
                && `Last export by ${lastExportedName}: ${props.lastExportTime}`}
            {props.lastExportTime &&
                props.exportStatus === 'EXPORT_ERROR'
                && `Export error by ${lastExportedName}: ${props.lastExportTime}`}
        </Text>
        {props.ventureLoanUrl && <Text
            as="a"
            target="_blank"
            rel="noreferrer"
            href={props.ventureLoanUrl}
            variant="blue"
            size="xs"
            underline>
            Ventures Log #
            {props.ventureLoanLogNumber}
        </Text>}
    </Stack>)
}