import { useRef, useState } from "react";

import { VenturesDataChangedMapperProps } from "./ventures-data-changed-mapper.types";

export const useVenturesDataChangedMapperState = (props: VenturesDataChangedMapperProps) => {
    const [value, setValue] = useState<string[]>(props.items?.map(item => item.id) ?? []);
    const [items] = useState(props.items ?? []);
    const sourceScrollAreaRef = useRef<HTMLDivElement>(null);
    const targetScrollAreaRef = useRef<HTMLDivElement>(null);
    const [selectedIds, setSelectedIds] = useState<string[]>([]);

    const onValueChange = (value: string[]) => {
        setValue(value);
    }

    const onScroll = (event: React.UIEvent<HTMLDivElement>, type: "SOURCE" | "TARGET") => {
        // get scroll position from event
        // and set it to the other scroll area
        const scrollArea = type === "SOURCE" ? targetScrollAreaRef.current : sourceScrollAreaRef.current;
        if (scrollArea) {
            scrollArea.scrollTop = event.currentTarget.scrollTop;
        }
    };

    const onSelectIds = (ids: string[]) => {
        setSelectedIds(ids);
    };

    const onExportConfirmClick = () => {
        props.onConfirm(selectedIds);
    };

    return {
        items,
        value,
        onValueChange,
        onScroll,
        sourceScrollAreaRef,
        targetScrollAreaRef,
        onSelectIds,
        onExportConfirmClick,
        selectedIds
    } as const;
};