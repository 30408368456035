import type { Theme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import LoadingToast from 'components/dist/organisms/LoadingToast';
import { parseISO } from "date-fns";
import { useRouter } from 'next/router';
import React, { useEffect } from "react";
import { toast } from 'react-toastify';
import type { LoanEntityDto, VentureExportDataChangeDTO } from 'src/backend';
import { VenturesExportStatus } from 'src/components/ventures/ventures-export-status';
import { QUERY_PARAM_EXPORT_TO_VENTURES_DIALOG_OPEN } from 'src/constants/loan';
import { LOAN_TABS } from 'src/constants/query-params';
import { Route } from 'src/constants/ui';
import { useUser } from 'src/hooks/use-user';
import { useExportToSharepointMutation } from 'src/services/loanApi';
import { useLazyGetLoanElementsQuery } from 'src/services/packageApi';
import { useExportLoanToVenturesMutation, useLazyCheckVenturesDataChangeQuery, useLazyCheckVenturesRequiredDataQuery, useVenturesDataChangeConfirmationMutation } from 'src/services/venturesApi';
import { addLenderToLoan, getLoan } from 'src/slices/loan';
import { loansManagerSlice } from 'src/slices/loan-manager';
import { getLoanPackage, postCreateLoanPackage } from "src/slices/view";
import { useDispatch } from "src/store";

import { useRoleActionsMenuHook } from '../role-actions-menu/role-actions-menu.hook';
import { LoanOverviewPackageProps } from './LoanOverviewPackage.types';
import { VenturesExportingStatus } from './ventures-exporting-status';


let _optimisticGenerateTimeout = null;

let _checkForPackageInterval = null;

type VENTURES_MODAL =
    | 'NO_PRIMARY_REAL_ESTATE_COLLATERAL_ADDRESS'
    | 'PRIMARY_NON_REAL_ESTATE_COLLATERAL_NO_POC_ADDRESS'
    | 'CONFIRM_FIRST_EXPORT_POC'
    | 'CONFIRM_POC_CHANGED'
    | 'FIELDS_MAPPER'
    | 'ENTITIES_FORM'
    | 'POC_PICKER'
    | 'NO_POC_NO_ENTITIES_ALERT'
    | "NO_COLLATERAL_ALERT";

export const useLoanOverviewPackage = (props: LoanOverviewPackageProps) => {
    const [exportLoanToVenture] = useExportLoanToVenturesMutation();
    const [exportLoanToSharePoint] = useExportToSharepointMutation();
    const [checkVenturesRequiredData, { currentData: checkVenturesRequiredDataData, isFetching: isVenturesCheckingDataLoading }] = useLazyCheckVenturesRequiredDataQuery()
    const [checkVenturesDataChanged, { currentData: venturesDataChangedData, isFetching: isCheckVenturesDataChangedLoading }] = useLazyCheckVenturesDataChangeQuery()
    const [getLoanElements] = useLazyGetLoanElementsQuery();
    const [confirmDataChange, { isLoading: isConfirmDataChangeLoading }] = useVenturesDataChangeConfirmationMutation()
    const [isCheckingForExport, setIsCheckingForExport] = React.useState(false);
    const [openDialog, setOpenDialog] = React.useState<VENTURES_MODAL>(null);
    const [venturePOCConfirmation, setVenturePOCConfirmation] = React.useState<{ firstPOCExportConfirmed: boolean, changedPocConfirmed: boolean }>({ firstPOCExportConfirmed: false, changedPocConfirmed: false });
    const [isOutdatedType, setIsOutdatedType] = React.useState<'zip' | 'pdf'>(null);
    const [isOptimisticGeneratingPackage, setIsOptimisticGeneratingPackage] = React.useState(false);
    const dispatch = useDispatch();
    const { company, user: loggedInUser } = useUser();
    const router = useRouter();
    const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
    const zipPackageExists = !Object.is(props.loan.zip.finished, null);
    const pdfPackageExists = !Object.is(props.loan.pdf.finished, null);
    const zipPackageGenerating = props.loan.zip.state == 'REQUESTED' || props.loan.zip.state == 'IN_PROGRESS' || isOptimisticGeneratingPackage;
    const pdfPackageGenerating = props.loan.pdf.state == 'REQUESTED' || props.loan.pdf.state == 'IN_PROGRESS' || isOptimisticGeneratingPackage;
    const isDownloadZipPackageDisabled = zipPackageGenerating || isOptimisticGeneratingPackage;
    const isDownloadPdfPackageDisabled = pdfPackageGenerating || isOptimisticGeneratingPackage;
    const isGeneratingPackage = zipPackageGenerating || pdfPackageGenerating;


    if ((props.loan.pdf.state === 'FINISHED' && props.loan.zip.state === 'FINISHED') && _checkForPackageInterval) {
        clearInterval(_checkForPackageInterval);
    }

    const checkIfPackageIsGenerated = React.useCallback(() => {
        _checkForPackageInterval = setInterval(() => {
            dispatch(getLoan(props.loan.id, false, true));
        }, 5000);
    }, [dispatch, props.loan.id]);

    const { onDelete } = useRoleActionsMenuHook();

    const handleGenerateLoanPackage = async () => {
        setIsOptimisticGeneratingPackage(true);
        if (_optimisticGenerateTimeout) {
            clearTimeout(_optimisticGenerateTimeout);
        }
        _optimisticGenerateTimeout = setTimeout(() => {
            setIsOptimisticGeneratingPackage(false);
        }, 10000);
        await dispatch(postCreateLoanPackage({ loanId: props.loan.id }));
        await dispatch(getLoan(props.loan.id, false, true));
        setIsOutdatedType(null);
    }

    const handleDialogClose = () => {
        setIsOutdatedType(null);
    }

    const handleDownloadOutdatedClick = () => {
        if (isOutdatedType === 'zip') {
            dispatch(getLoanPackage({ loanId: props.loan.id, type: 'zip' }));
        } else if (isOutdatedType === 'pdf') {
            dispatch(getLoanPackage({ loanId: props.loan.id, type: 'pdf' }));
        }
        handleDialogClose();
    }

    const checkIfPackageIsOutOfDate = async () => {
        const elements = await getLoanElements({ id: props.loan.id, view: 'CONVENTIONAL' }).unwrap();
        let isPackageOutOfDate = false;
        if (elements.list.length > 0) {
            const lastModifiedDate = elements.list
                .filter(element => !!element.answer)
                .reduce((prev, curr) => {
                    // Parse dates
                    const prevDate = new Date(prev);
                    const currDate = new Date(curr.lastModifiedDate);
                    const currAnswerDate = curr?.answer?.document?.lastModifiedDate ? new Date(curr.answer.document.lastModifiedDate) : new Date(0);

                    // Get max date
                    const maxDate = new Date(Math.max(prevDate.getTime(), currDate.getTime(), currAnswerDate.getTime()));

                    // Return as string
                    return maxDate.toISOString();
                }, new Date(0).toISOString());

            if (!!lastModifiedDate && !!props.loan.pdf.finished) {
                isPackageOutOfDate = parseISO(lastModifiedDate) > parseISO(props.loan.pdf.finished);
            }
        }
        return isPackageOutOfDate;
    }
    const handleGetZIPLoanPackage = async (event) => {
        event.preventDefault();
        const isPackageOutOfDate = await checkIfPackageIsOutOfDate();
        if (!zipPackageExists) {
            handleGenerateLoanPackage();
        } else if (isPackageOutOfDate) {
            setIsOutdatedType('zip');
        } else {
            dispatch(getLoanPackage({ loanId: props.loan.id, type: 'zip' }));
        }
    }

    const exportTVenturesLastStep = async (dataChangeId: string, dataChanged?: VentureExportDataChangeDTO) => {
        const VENTURES_TOAST_ID = Math.random().toString(36).substring(7)

        try {
            if (!!dataChanged) {
                await confirmDataChange({ loanId: props.loan.id, payload: dataChanged }).unwrap()
            }
            const exportPromise = exportLoanToVenture({ loanId: props.loan.id, dataChangeId })
            toast(<LoadingToast
                title=""
                hint=""
                status="LOADING"
                onCancel={() => {
                    exportPromise.abort()
                }}
            >
                <VenturesExportingStatus
                    status={'EXPORTING'}
                    loanId={props.loan.id}
                    loanShortCode={props.loan.shortCode}
                    loanProjectName={props.loan.projectName}
                />
            </LoadingToast>, {
                toastId: VENTURES_TOAST_ID,
                autoClose: false,
                type: "default",
                position: isMobile ? "bottom-center" : "bottom-right"
            })
            await exportPromise.unwrap()
            const loan = await dispatch(getLoan(props.loan.id, false, true));
            toast.update(VENTURES_TOAST_ID, {
                render: <LoadingToast
                    title=""
                    hint=""
                    status="SUCCESS"
                    onCancel={() => { }}
                >
                    <VenturesExportingStatus
                        status={'EXPORT_COMPLETE'}
                        loanId={loan.id}
                        loanShortCode={loan.shortCode}
                        loanProjectName={loan.projectName}
                    />
                    <VenturesExportStatus
                        lastExportTime={loan.ventureLastExportTime}
                        ventureExportedBy={loan.ventureExportedBy}
                        exportStatus={loan.ventureExportStatus}
                        ventureLoanLogNumber={loan.ventureLoanLogNumber}
                        ventureLoanUrl={loan.ventureLoanUrl}
                    />
                </LoadingToast>,
                autoClose: false,
                type: "default"
            })


        } catch (error) {
            let errorMessage = "Export to Ventures Failed"
            if (error?.errors?.message) {
                errorMessage = error.errors.message;
            }
            toast.update(VENTURES_TOAST_ID, {
                render: <LoadingToast
                    title={errorMessage}
                    hint="Please try again"
                    status="FAILURE"
                    onCancel={() => { }}
                >
                    <VenturesExportingStatus
                        status={'EXPORT_ERROR'}
                        loanId={props.loan.id}
                        loanShortCode={props.loan.shortCode}
                        loanProjectName={props.loan.projectName}
                    />
                </LoadingToast>,
                autoClose: false,
                type: "default"
            })
        } finally {
            dispatch(getLoan(props.loan.id, false, true));
        }
    }

    const onExportToVenturesClick = async (args?: {
        firstPOCExportConfirmed?: boolean,
        changedPocConfirmed?: boolean
    }) => {
        try {
            setIsCheckingForExport(true)
            // first we need to check required data in ventures
            const response = await checkVenturesRequiredData({ loanId: props.loan.id }).unwrap()
            // if there is no collateral added 
            // we need to display dialog to add new collateral
            if (response.missingPrimaryCollateral && response.collaterals.length === 0) {
                setOpenDialog("NO_COLLATERAL_ALERT")
                throw new Error("STOP_EXPORTING_TO_VENTURES")
            } else if (response.missingPOC && response.entities.length === 0) {
                // if there is no primary operating company
                // and there is no other entities to set as POC
                // we need to add one
                setOpenDialog("NO_POC_NO_ENTITIES_ALERT");
                throw new Error("STOP_EXPORTING_TO_VENTURES")
            } else if (response.primaryCollateralMissingAddress) {
                // if there is a collateral but it's missing the address
                // display dialog to add address
                setOpenDialog("NO_PRIMARY_REAL_ESTATE_COLLATERAL_ADDRESS")
                throw new Error("STOP_EXPORTING_TO_VENTURES")
            } else if (response.missingPOCAddress) {
                // if there is a collateral but it's missing the address
                // display dialog to add address
                setOpenDialog("PRIMARY_NON_REAL_ESTATE_COLLATERAL_NO_POC_ADDRESS")
                throw new Error("STOP_EXPORTING_TO_VENTURES")
            } else if (response.firstPOCExport && !args?.firstPOCExportConfirmed) {
                // if this is the first export to ventures
                // we need user confirmation to set the POC
                setOpenDialog("CONFIRM_FIRST_EXPORT_POC")
                throw new Error("STOP_EXPORTING_TO_VENTURES")
            } else if (response.changedPOC && !args?.changedPocConfirmed) {
                // if poc changed we need to display
                // confirmation dialog to user
                // that we are creating a new loan
                setOpenDialog("CONFIRM_POC_CHANGED")
                throw new Error("STOP_EXPORTING_TO_VENTURES")
            } else if ((response.missingPrimaryCollateral && response.collaterals.length > 0) || (response.missingPOC && response.entities.length > 0)) {
                // if there is entities and they have missing data
                // we need to display the form mapper
                setOpenDialog("POC_PICKER")
                throw new Error("STOP_EXPORTING_TO_VENTURES")
            } else if (response.entities.length > 0) {
                setOpenDialog("ENTITIES_FORM")
                throw new Error("STOP_EXPORTING_TO_VENTURES")
            }
            // get list of changed fields in ventures
            // so user can select what to override
            const dataChangeResponse = await checkVenturesDataChanged({ loanId: props.loan.id }).unwrap()
            if (dataChangeResponse.items.length > 0) {
                setOpenDialog('FIELDS_MAPPER')
                throw new Error("STOP_EXPORTING_TO_VENTURES")
            }
            // if everything is ok we can export the loan to ventures
            exportTVenturesLastStep(dataChangeResponse.id)
        } catch (error) {
            // if message is not STOP_EXPORTING_TO_VENTURES
            // we need to display the error
            if (error.message !== "STOP_EXPORTING_TO_VENTURES") {
                let message = error.message ?? "Error exporting to ventures"
                if ('data' in error) {
                    message = error.data.message
                }
                toast.error(message)
            }
        } finally {
            setIsCheckingForExport(false)
        }
    }

    const onConfirmPOCExport = async () => {
        setVenturePOCConfirmation(prevState => ({
            ...prevState,
            firstPOCExportConfirmed: true
        }));
        onExportToVenturesClick({
            firstPOCExportConfirmed: true
        })
    }

    const onConfirmPOCChangeExport = async () => {
        setVenturePOCConfirmation(prevState => ({
            ...prevState,
            changedPocConfirmed: true
        }));
        onExportToVenturesClick({
            changedPocConfirmed: true
        })
    }

    const onAfterAddRealEstateAddress = () => {
        try {
            setOpenDialog(null)
            onExportToVenturesClick(venturePOCConfirmation)
        } catch (error) {
            toast.error("Error exporting to ventures")
        }
    }

    const onSetupEntitiesComplete = () => {
        try {
            setOpenDialog(null)
            // refresh loan to display new labels in the UI
            dispatch(getLoan(props.loan.id, false, true));
            onExportToVenturesClick(venturePOCConfirmation)
        } catch (error) {
            toast.error("Error exporting to ventures")
        }
    }

    const handleGetPDFLoanPackage = async (event) => {
        event.preventDefault();
        const isPackageOutOfDate = await checkIfPackageIsOutOfDate();
        if (!pdfPackageExists) {
            handleGenerateLoanPackage();
        } else if (isPackageOutOfDate) {
            setIsOutdatedType('pdf');
        } else {
            dispatch(getLoanPackage({ loanId: props.loan.id, type: 'pdf' }));
        }
    }

    const onConfirmVenturesDataChange = async (selectedIds: string[]) => {
        try {
            setOpenDialog(null)
            // get items with selected fields
            const selectedItems = venturesDataChangedData.items
                .filter(item => item.fields.some(field => selectedIds.includes(field.id)))
                .map(item => ({
                    ...item,
                    fields: item.fields.filter(field => selectedIds.includes(field.id))
                }))
            exportTVenturesLastStep(venturesDataChangedData.id, {
                id: venturesDataChangedData.id,
                items: selectedItems
            })
        } catch {
            toast.error("Error exporting to ventures")
        }
    }

    const onAddPocClick = () => {
        router.push({
            pathname: Route.CREATE_LOAN_COMPANY,
            query: {
                loanId: props.loan.id,
                tab: LOAN_TABS.DETAILS,
                [QUERY_PARAM_EXPORT_TO_VENTURES_DIALOG_OPEN]: true,
            }
        })
    }

    const onAddCollateral = () => {
        router.push({
            pathname: Route.CREATE_LOAN_ASSET,
            query: {
                loanId: props.loan.id,
                [QUERY_PARAM_EXPORT_TO_VENTURES_DIALOG_OPEN]: true,
                tab: LOAN_TABS.DETAILS
            }
        })
    }

    const onAddCollateralAddress = () => {
        router.push({
            pathname: Route.LOAN_ASSET_EDIT,
            query: {
                loanId: props.loan.id,
                assetId: checkVenturesRequiredDataData.primaryCollateralMissingAddress?.sherpaEntity?.id,
                [QUERY_PARAM_EXPORT_TO_VENTURES_DIALOG_OPEN]: true,
                tab: LOAN_TABS.DETAILS
            }
        })
    }

    const onExportToSharepointClick = async () => {
        try {
            await exportLoanToSharePoint({ loanId: props.loan.id }).unwrap()
            toast.success("Export to Sharepoint started")
        } catch (error) {
            toast.error("Error exporting to Sharepoint")
        }

    }

    const onAddMeClick = async () => {
        await dispatch(addLenderToLoan({
            loanId: props.loan.id,
            payload: {
                contactVisibleToBorrowers: false,
                contactVisibleToLenders: false,
                newToLoan: true,
                appUser: loggedInUser.id,
                leadLender: false,
                leadBorrower: false,
                role: loggedInUser.loggedCompanyRole,
                canAcceptFiles: true,
                visibleToBorrower: false,
                borrowerType: null,
                contactRelation: null
            }
        }))
    }

    const onRemoveMeClick = async () => {
        const roleOnLoan = props.loan.loanRoles.find(role => role.user.id === loggedInUser.id);
        await onDelete({ roleId: roleOnLoan.id, loanId: props.loan.id });
        // if the role we removed is the current user's role, 
        // and the loan review status is not ready for underwriting,
        // then we need to redirect them to the dashboard
        if (roleOnLoan &&
            loggedInUser.loggedCompanyRole === "UNDERWRITER_LENDER" &&
            roleOnLoan.user.id === loggedInUser.id &&
            props.loan.reviewStatus !== 'READY_FOR_UNDERWRITING') {
            // remove loan from redux
            dispatch(loansManagerSlice.actions.removeLoan(props.loan.id));
            router.push({
                pathname: Route.HOME,
            })
        }
    }
    const exportToVenturesQueryParam = router.query[QUERY_PARAM_EXPORT_TO_VENTURES_DIALOG_OPEN];
    useEffect(function openExportDialog() {

        if (exportToVenturesQueryParam) {
            const {
                [QUERY_PARAM_EXPORT_TO_VENTURES_DIALOG_OPEN]: _,
                ...query
            } = router.query;
            router.replace({
                pathname: router.pathname,
                query
            })
            onExportToVenturesClick()
        }
    }, []);

    useEffect(function refreshPackageUi() {
        if (props.loan.zip.state === 'REQUESTED' || props.loan.pdf.state === 'REQUESTED') {
            checkIfPackageIsGenerated();
        }

    }, [
        props.loan.zip.state,
        props.loan.pdf.state,
        checkIfPackageIsGenerated]);

    const pocEntity = props.loan.loanEntities.find(entity => entity.label.includes('PRIMARY_OPERATION_COMPANY'));
    const venturesEntities: LoanEntityDto[] = []
    if (checkVenturesRequiredDataData?.entities) {
        venturesEntities.push(...checkVenturesRequiredDataData.entities)
    }
    if (checkVenturesRequiredDataData?.collaterals) {
        venturesEntities.push(...checkVenturesRequiredDataData.collaterals)
    }

    const onCloseVenturesDialog = () => {
        // remove export to ventures query param from url

        setOpenDialog(null)
    }

    return {
        outdatedMenuOpen: !!isOutdatedType,
        isMobile,
        primaryCollateralMissingAddress: checkVenturesRequiredDataData?.primaryCollateralMissingAddress,
        primaryPOCAddressCollateral: checkVenturesRequiredDataData?.primaryPOCAddressCollateral,
        isGeneratingPackage,
        isPackageExist: zipPackageExists || pdfPackageExists,
        outdatedType: isOutdatedType,
        isDownloadPdfPackageDisabled,
        isDownloadZipPackageDisabled,
        openDialog,
        isVenturesEnabled: company?.venturesEnabled,
        isExportToVenturesDisabled: props.loan.ventureExportStatus === 'EXPORTING',
        isVenturesCheckingDataLoading,
        isConfirmDataChangeLoading,
        isCheckVenturesDataChangedLoading,
        venturesDataChangedItems: venturesDataChangedData?.items,
        venturesEntities,
        isCheckingForExport,
        pocEntity,
        isUserAddedToLoan: props.loan.loanRoles.some(role => role.user.id === loggedInUser.id),
        collateralName: checkVenturesRequiredDataData?.primaryCollateralName,
        onExportToSharepointClick,
        onAfterAddRealEstateAddress,
        onSetupEntitiesComplete,
        onCloseVenturesDialog,
        onExportToVenturesClick,
        onConfirmPOCChangeExport,
        onConfirmVenturesDataChange,
        onAddPocClick,
        onAddCollateral,
        onAddMeClick,
        onAddCollateralAddress,
        onRemoveMeClick,
        onConfirmPOCExport,
        onDownloadPdfClick: handleGetPDFLoanPackage,
        onDownloadZipClick: handleGetZIPLoanPackage,
        onDownloadOutdatedClick: handleDownloadOutdatedClick,
        onGenerateLoanPackageClick: handleGenerateLoanPackage,
        onOutdatedMenuClose: handleDialogClose,
    } as const;
}

