import { useFormik } from "formik";
import { BusinessRequestDto } from "src/backend";
import { usePostUpSomeBusinessEntityInformationMutation } from "src/services/entityApi";
import { getLoan } from "src/slices/loan";
import { useDispatch } from "src/store";

import { VenturesNoPocAddressProps } from "./ventures-no-poc-address.types";
import { VenturesNoPOCAddressValidation } from "./ventures-no-poc-address.validation";

export const useVenturesNoPocAddressState = (props: VenturesNoPocAddressProps) => {
    const [postUpSomeBusinessInformation] = usePostUpSomeBusinessEntityInformationMutation()
    const dispatch = useDispatch()

    const information: any = props.pocEntity.mostRecentUserInformation
    const formik = useFormik<BusinessRequestDto>({
        validationSchema: VenturesNoPOCAddressValidation,
        initialValues: {
            annualSales: information.annualSales,
            dbaName: information.dbaName,
            duns: information.duns,
            ein: information.ein,
            emailAddress: information.emailAddress,
            entityName: information.entityName,
            establishedDate: information.establishedDate,
            mailingAddress: information.mailingAddress,
            mailingSameAsCompanyAddress: information.mailingSameAsCompanyAddress,
            naics: information.naics,
            numberOfEmployees: information.numberOfEmployees,
            phoneNumber: information.phoneNumber,
            website: information.website,
            companyAddress: {
                citySuburb: '',
                country: '',
                postOfficeBox: '',
                postalCode: '',
                stateProvinceRegion: '',
                streetAddressLine1: '',
                streetAddressLine2: '',
                currentAddress: true,
                moveInDate: null,
                moveOutDate: null,
            }
        },
        onSubmit: async (values) => {
            formik.setSubmitting(true);
            // update entity information
            await postUpSomeBusinessInformation({
                entityId: props.pocEntity.sherpaEntity.id,
                information: values
            }).unwrap()
            // refresh loan
            await dispatch(getLoan(props.loanId))
            formik.setSubmitting(false);
            props.onAfterAddressAdd()
        },
    })

    return {
        formik
    }
}