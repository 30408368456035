import { EntityMlaFields } from "src/types/loan";

export const getEntityTotalPurchasePrice = (fields: EntityMlaFields): number => {
    const totalPrice: number = fields.totalCompletionCost ?
        getIntegerValue(fields.totalCompletionCost)
        : (getIntegerValue(fields.repairCost) + getIntegerValue(fields.purchasePrice) + getIntegerValue(fields.lotPurchasePrice) + getIntegerValue(fields.constructionCost))
    return totalPrice
}

const getIntegerValue = (value: string) => {
    if (!value) return 0
    return value ? parseInt(String(value).replace(/,/g, '')) : 0
}
