import Button from 'components/dist/atoms/Button';
import Icon from 'components/dist/atoms/Icon';
import Separator from 'components/dist/atoms/Separator';
import Stack from 'components/dist/atoms/Stack';
import Text from 'components/dist/atoms/Text';

import { useVenturesDataChangedMapperState } from './ventures-data-changed-dialog.state';
import { VenturesDataChangedMapperProps } from './ventures-data-changed-mapper.types';
import { VenturesDataChangedMapperFields } from './ventures-data-changed-mapper-fields.component';

export const VenturesDataChangedMapper = (props: VenturesDataChangedMapperProps) => {
    const state = useVenturesDataChangedMapperState(props);

    return <Stack space="md" className='flex-1 overflow-hidden'>
        <Stack space="md" className='p-4' row>
            <Icon name="BigExclamation" width={25} height={25} className="shrink-0 text-black-10" />
            <Stack space="sm">
                <Text size="sm" weight="medium">Export to Ventures</Text>
                <Text size="sm">Here are the items that have been changed and its version on ventures. Are you sure you want to export and override all items?</Text>
            </Stack>
        </Stack>
        <Separator />
        <Stack space="md" className='p-4 items-stretch overflow-hidden flex-1 sm:flex-row'>
            <VenturesDataChangedMapperFields
                ref={state.sourceScrollAreaRef}
                selectedIds={state.selectedIds}
                value={state.value}
                onValueChange={state.onValueChange}
                items={state.items}
                onSelect={state.onSelectIds}
                onScroll={event => state.onScroll(event, "SOURCE")}
                type="SOURCE" />
            <VenturesDataChangedMapperFields
                ref={state.targetScrollAreaRef}
                value={state.value}
                onValueChange={state.onValueChange}
                onScroll={event => state.onScroll(event, "TARGET")}
                items={state.items}
                type="TARGET" />
        </Stack>
        <Separator />
        <Stack space="sm" row className='justify-end sm:pb-4 px-4 shrink-0'>
            <Button
                onClick={props.onCancel}
                variant="outline">
                Cancel
            </Button>
            <Button
                disabled={state.selectedIds.length === 0}
                onClick={state.onExportConfirmClick}
            >
                Confirm and Export
            </Button>
        </Stack>
    </Stack>
}