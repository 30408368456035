import Icon from 'components/dist/atoms/Icon';
import { Archive, Check, Clock, Filter, GoogleDocs, InfoCircle, Minus, MoneySquare, PageEdit, ShoppingBagMinus, WarningCircle } from "iconoir-react";
import { LoanReviewStatus } from "src/backend";
import { MysherpasTooltip } from "src/components/common/mysherps-tooltip";
import { getReviewStatusTooltip } from "src/utils/loan/get-review-status-tooltip";

import { LoanReviewIconStyles } from "./loan-review-icon.styles";

interface LoanReviewIconProps {
    status: LoanReviewStatus;
}

export const LoanReviewIcon = (props: LoanReviewIconProps) => (<MysherpasTooltip
    title={getReviewStatusTooltip(props.status)}>
    <LoanReviewIconStyles.Wrapper
        data-status={props.status}
        className='loan-ready-for-review-icon-wrapper' >
        {props.status === 'IN_PROGRESS' && <Icon name="Origination" width={20} height={20} strokeWidth={1.5} />}
        {props.status === 'READY_FOR_REVIEW_PENDING' && <Clock fr="" />}
        {['REVIEW_COMPLETE_ACCEPTED', 'CLOSING'].includes(props.status) && <Check fr="" />}
        {props.status === 'READY_FOR_REVIEW_REVIEW' && <PageEdit fr="" />}
        {props.status === 'READY_FOR_UNDERWRITING' && <GoogleDocs fr="" />}
        {props.status === 'ARCHIVE' && <Archive fr="" />}
        {props.status === 'LEAD' && <Filter fr="" />}
        {props.status === 'IN_SERVICE' && <MoneySquare fr="" />}
        {props.status === 'IN_DEFAULT' && <InfoCircle fr="" />}
        {props.status === 'WITHDRAWN' && <ShoppingBagMinus fr="" />}
        {props.status === 'REVIEW_COMPLETE_CONDITIONAL' && <WarningCircle fr="" />}
        {['REVIEW_COMPLETE_REJECTED', 'HOLD'].includes(props.status) && <Minus fr="" />}
    </LoanReviewIconStyles.Wrapper>
</MysherpasTooltip>)