import { EntityMlaFields } from "src/types/loan"

import { getEntityLowestAmount } from "./calculations/get-entity-lowest-amount"

export const calculateEntityMla = (fields: EntityMlaFields): number => {
    const lowestAssetValue = getEntityLowestAmount(fields)

    if (getIntegerValue(fields.internalReviewValueForMla)) {
        return getIntegerValue(fields.internalReviewValueForMla)
    } else if (lowestAssetValue > 0) {
        return (getIntegerValue(fields.lenderPolicyLtv) / 100) * lowestAssetValue
    } else {
        return 0
    }
}

const getIntegerValue = (value: string) => {
    if (!value) return 0
    return value ? parseInt(String(value).replace(/,/g, '')) : 0
}

