import MuiChip from "@mui/material/Chip"
import { styled } from "@mui/material/styles"
import { themeV2 } from "src/theme/mysherpas-theme-option"

const Chip = styled(MuiChip)(({ theme }) => ({
    padding: theme.spacing(0.5, 0),
    fontSize: theme.typography.pxToRem(12),
    fontWeight: theme.typography.fontWeightMedium,
    textTransform: 'capitalize',
    '&.MuiChip-colorDefault': {
        backgroundColor: "#F4F6FF",
        color: theme.palette.text.primary,
    },
    '&.MuiChip-colorWarning': {
        backgroundColor: themeV2.colors.yellow[10],
        color: themeV2.colors.yellow.dark,
    },
    '&.MuiChip-colorSuccess': {
        backgroundColor: themeV2.colors.green[50],
        color: themeV2.colors.green[10],
        '&[data-light="true"]': {
            backgroundColor: themeV2.colors.green[10],
            color: themeV2.colors.green[50],
        },
    },
    '&.MuiChip-colorError': {
        backgroundColor: themeV2.colors.red[10],
        color: themeV2.colors.red.dark,
    },
    '&.MuiChip-root': {
        borderRadius: 100,
    },
    '& .MuiSvgIcon-root ~ span': {
        paddingRight: theme.spacing(2),
    }
}))

export const LabelChipStyles = {
    Chip
}