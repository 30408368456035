import type { LoanEntityDto } from "src/backend";
import { EntityLabel, LoanCompanyLabels } from 'src/types/entity';

export const getEntityLabels = (args: { entity: Pick<LoanEntityDto, 'label' | 'userLabels'>, short: boolean }): string[] => {
    const labels: string[] = []
    LoanCompanyLabels.forEach(label => {
        if (args.entity.label.includes(label)) {
            labels.push(args.short ? EntityLabel[label].short : EntityLabel[label].long)
        }
    })
    args.entity.label.filter(label => !LoanCompanyLabels.includes(label))
        .filter(label => label !== 'NOT_A_GUARANTOR')
        .forEach(label => {
            if (Object.keys(EntityLabel).includes(label)) {
                labels.push(args.short ? EntityLabel[label].short : EntityLabel[label].long)
            } else {
                labels.push(label.toLowerCase())
            }
        })
    args.entity.userLabels.forEach(label => {
        labels.push(label.title)
    })
    return labels;
}