import { useFormik } from "formik";
import { RealEstateRequestDto } from "src/backend";
import { usePostUpSomeRealEstateInformationMutation } from "src/services/entityApi";
import { getLoan } from "src/slices/loan";
import { useDispatch } from "src/store";

import { VenturesNoPrimaryRealEstateAddressProps } from "./ventures-no-primary-real-estate-address.types";
import { VenturesNoPrimaryRealEstateAddressValidation } from "./ventures-no-primary-real-estate-address.validation";

export const useVenturesNoPrimaryRealEstateAddressState = (props: VenturesNoPrimaryRealEstateAddressProps) => {
    const [postUpSomeRealEstateInformation] = usePostUpSomeRealEstateInformationMutation()
    const dispatch = useDispatch()

    const information: any = props.collateral.mostRecentUserInformation
    const formik = useFormik<RealEstateRequestDto>({
        validationSchema: VenturesNoPrimaryRealEstateAddressValidation,
        initialValues: {
            afterLeaseValue: information.afterLeaseValue,
            afterRepairValue: information.afterRepairValue,
            appraisedValue: information.appraisedValue,
            asCompletedValue: information.asCompletedValue,
            buildingSquareFootage: information.buildingSquareFootage,
            constructionCost: information.constructionCost,
            costPerSquareFoot: information.costPerSquareFoot,
            earnestMoneyDeposit: information.earnestMoneyDeposit,
            landSquareFootage: information.landSquareFootage,
            leasedSquareFootage: information.leasedSquareFootage,
            lotPurchasePrice: information.lotPurchasePrice,
            mlsId: information.mlsId,
            ownerOccupiedSquareFootage: information.ownerOccupiedSquareFootage,
            purchasePrice: information.purchasePrice,
            repairCost: information.repairCost,
            repairCostPerSquareFoot: information.repairCostPerSquareFoot,
            taxOrParcelId: information.taxOrParcelId,
            totalCompletionCost: information.totalCompletionCost,
            streetAddress: {
                citySuburb: '',
                country: '',
                postOfficeBox: '',
                postalCode: '',
                stateProvinceRegion: '',
                streetAddressLine1: '',
                streetAddressLine2: '',
                currentAddress: false,
                moveInDate: null,
                moveOutDate: null,
            }
        },
        onSubmit: async (values) => {
            formik.setSubmitting(true);
            // update entity information
            await postUpSomeRealEstateInformation({
                entityId: props.collateral.sherpaEntity.id,
                information: values
            }).unwrap()
            // refresh loan
            await dispatch(getLoan(props.loanId))
            formik.setSubmitting(false);
            props.onAfterAddressAdd()
        },
    })

    return {
        formik
    }
}