import Button from "components/dist/atoms/Button";
import Icon from "components/dist/atoms/Icon";
import Input from "components/dist/atoms/Input";
import RadioGroup from "components/dist/atoms/RadioGroup";
import Separator from "components/dist/atoms/Separator";
import Stack from "components/dist/atoms/Stack";
import Text from "components/dist/atoms/Text";
import { useState } from "react";
import { toast } from "react-toastify";
import { LoanEntityDto, LoanEntityLabel, SherpaEntityType } from "src/backend";
import { useAddLabelsToEntityMutation } from "src/services/entityApi";
import { getLoan } from "src/slices/loan";
import { useDispatch } from "src/store";

import { VenturesPickPOCListItem } from "./ventures-pick-poc-list-item";


interface VenturesPickPOCProps {
    onSuccess: () => void;
    onCancel: () => void;
    entities: LoanEntityDto[]
    loanId: string;
}

const typeLabel = {
    ['TRUST']: "Trusts",
    ['COMPANY']: "Companies",
    ['ASSET']: "Collateral offered",
} as const;

const getDialogLabels = (entities: LoanEntityDto[]) => {
    const hasAnAsset = entities.some(entity => entity.sherpaEntity.type.includes('ASSET'));
    const hasCompanyOrTrust = entities.some(entity => entity.sherpaEntity.type.includes('COMPANY') || entity.sherpaEntity.type.includes('TRUST'));
    // if we have asset type and (trust or company type), we can continue to export
    if (hasAnAsset && hasCompanyOrTrust) {
        return ({
            title: "Add a Primary Operating Company (POC) & Primary Collateral to continue to export",
            ctaText: "Add"
        })
    }
    // else if we only have asset type
    else if (hasAnAsset) {
        return ({
            title: "Add a Primary Collateral to continue to export",
            ctaText: "Add Primary Collateral"
        })
    } else {
        return ({
            title: "Add Primary Operating Company (POC)",
            ctaText: "Add POC"
        })

    }
}

export const VenturesPickPOC = (props: VenturesPickPOCProps) => {
    const [addLabelsToEntity, { isLoading }] = useAddLabelsToEntityMutation();
    const dispatch = useDispatch();

    const [formState, setFormState] = useState<{
        filter: string;
        selectedEntity: LoanEntityDto | null;
        selectedCollateral: LoanEntityDto | null;
    }>({
        filter: "",
        selectedEntity: null,
        selectedCollateral: null
    });

    const entitiesGroupedByType = props.entities
        ?.filter(entities => entities.sherpaEntity.name.toLowerCase().includes(formState.filter.toLowerCase()))
        .reduce((acc, entity) => {
            let type = entity.sherpaEntity.type;
            // if sherpa entity type ends with ASSET, it should be treated as collateral
            if (type.includes('ASSET')) {
                type = 'ASSET';
            }
            if (!acc[typeLabel[type]]) {
                acc[typeLabel[type]] = [];
            }
            acc[typeLabel[type]].push(entity);
            return acc;
        }, {} as Record<SherpaEntityType, LoanEntityDto[]>);

    const entitiesGroupedByTypeArray = Object.entries(entitiesGroupedByType);
    const collateralEntities = entitiesGroupedByTypeArray.filter(([title]) => title === typeLabel['ASSET']);
    const otherEntities = entitiesGroupedByTypeArray.filter(([title]) => title !== typeLabel['ASSET']);

    const onSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFormState(prevState => ({
            ...prevState,
            filter: event.target.value
        }));
    };

    const onSelectedEntityChange = (entityId: string) => {
        const selectedEntity = props.entities.find(entity => entity.id === entityId);
        setFormState(prevState => ({
            ...prevState,
            selectedEntity
        }));
    }

    const onSelectCollateralChange = (entityId: string) => {
        const selectedCollateral = props.entities.find(entity => entity.id === entityId);
        setFormState(prevState => ({
            ...prevState,
            selectedCollateral
        }));
    }

    const onAddPOCClick = async () => {
        try {
            // if we are still loading throw an error
            if (isLoading) {
                throw new Error("Please wait for the previous operation to complete.");
            }
            if (formState.selectedEntity) {
                const labels: LoanEntityLabel[] = [
                    ...formState.selectedEntity.label,
                    'PRIMARY_OPERATION_COMPANY'
                ]
                await addLabelsToEntity({
                    sherpaEntityId: formState.selectedEntity.sherpaEntity.id,
                    loanId: props.loanId,
                    label: labels,
                    userLabels: formState.selectedEntity.userLabels.map(label => label.id)
                })

            } else if (otherEntities.length > 0) {
                throw new Error("Please select an entity to add as POC.");
            }
            if (formState.selectedCollateral) {
                const labels: LoanEntityLabel[] = [
                    ...formState.selectedCollateral.label,
                    'PRIMARY_COLLATERAL'
                ]
                await addLabelsToEntity({
                    sherpaEntityId: formState.selectedCollateral.sherpaEntity.id,
                    loanId: props.loanId,
                    label: labels,
                    userLabels: formState.selectedCollateral.userLabels.map(label => label.id)
                })

            } else if (collateralEntities.length > 0) {
                throw new Error("Please select an entity to add as Primary collateral.");
            }
            dispatch(getLoan(props.loanId));
            props.onSuccess();
        } catch (error) {
            toast.error(error.message)
        }
    }

    const dialogLabels = getDialogLabels(props.entities);

    return (<Stack space="sm">
        <Stack space="sm" row className="px-3 pt-3" items="center">
            <Icon name="BigExclamation" className='text-black-10' width={28} height={28} />
            <Text size="sm" className="items-center">{dialogLabels.title}</Text>
        </Stack>
        <Separator />
        <div className="px-4">
            <Input
                wrapClassName="focus-within:border-blue-100"
                variant="sm"
                placeholder="Search" onChange={onSearchChange}>
                <Input.Prefix>
                    <Icon className="text-black-primary" name="Search" width={18} height={18} strokeWidth={1.5} />
                </Input.Prefix>
            </Input>
        </div>
        {entitiesGroupedByTypeArray.length === 0 && <Stack space="xl" className="h-72 max-h-full py-10" justify='center' items="center">
            <Icon name="BigExclamation" width={64} height={64} className="shrink-0 text-black-10" />
            <Text size="sm" classNam="w-56">
                No result matched your search. Please try again
            </Text>
        </Stack>}
        {entitiesGroupedByTypeArray.length > 0 && <Stack className="h-72 max-h-full scrollbar-stable overflow-y-scroll">
            <RadioGroup
                onValueChange={onSelectedEntityChange}
                className="px-2 flex flex-col gap-5"
            >
                {otherEntities.map(([title, items]) => <VenturesPickPOCListItem
                    key={title}
                    title={title}
                    items={items}
                />)}
            </RadioGroup>
            <RadioGroup
                onValueChange={onSelectCollateralChange}
                className="px-2 flex flex-col gap-5"
            >
                {collateralEntities.map(([title, items]) => <VenturesPickPOCListItem
                    key={title}
                    title={title}
                    items={items}
                />)}
            </RadioGroup>
        </Stack>}
        <Separator />
        <Stack space="sm" row className='justify-end pb-4 px-4'>
            <Button
                onClick={props.onCancel}
                className="min-w-20"
                variant="outline">
                Cancel
            </Button>
            <Button
                loading={isLoading}
                onClick={onAddPOCClick}>
                {dialogLabels.ctaText}
            </Button>
        </Stack>
    </Stack>)
}