import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import Button from 'components/dist/atoms/Button';
import Dialog from 'components/dist/atoms/Dialog';
import Drawer from 'components/dist/atoms/Drawer';
import Text from 'components/dist/atoms/Text';
import ActionAlertDialog from 'components/dist/molecules/ActionAlertDialog';
import LoadingBox from 'components/dist/molecules/LoadingBox';
import React from "react";
import { DialogContainer } from "src/components/common/dialog-container";

import { useLoanOverviewPackage } from './loan-overview-package.hook';
import { LoanOverviewPackageProps } from './LoanOverviewPackage.types';
import { VenturesDataChangedMapper } from './ventures-data-changed-mapper';
import { VenturesEntitiesSetup } from './ventures-entities-setup/ventures-entities-setup.component';
import { VenturesNoPocAddress } from './ventures-no-poc-address';
import { VenturesNoPrimaryRealEstateAddress } from './ventures-no-primary-real-estate-address';
import { VenturesPickPOC } from './ventures-pick-poc';

// the props are the return value of the useLoanOverviewPackage hook
interface LoanOverviewPackageModalsProps extends ReturnType<typeof useLoanOverviewPackage> {
    loan: LoanOverviewPackageProps['loan'];
}

export const LoanOverviewPackageModals = (props: LoanOverviewPackageModalsProps) => {

    const isADialogOpen = !!props.outdatedMenuOpen ||
        props.isCheckingForExport ||
        props.openDialog === "FIELDS_MAPPER" ||
        props.openDialog === "NO_POC_NO_ENTITIES_ALERT" ||
        props.openDialog === "NO_COLLATERAL_ALERT" ||
        props.openDialog === "CONFIRM_FIRST_EXPORT_POC" ||
        props.openDialog === "NO_PRIMARY_REAL_ESTATE_COLLATERAL_ADDRESS" ||
        props.openDialog === "PRIMARY_NON_REAL_ESTATE_COLLATERAL_NO_POC_ADDRESS" ||
        props.openDialog === "CONFIRM_POC_CHANGED" ||
        props.openDialog === "ENTITIES_FORM" ||
        props.openDialog === "POC_PICKER"

    if (!isADialogOpen) return null;

    const DialogDrawer = !props.isMobile ? Dialog : Drawer;

    return <>
        {!!props.outdatedMenuOpen && <DialogContainer
            open={!!props.outdatedMenuOpen}
            onClose={props.onOutdatedMenuClose}>
            <DialogTitle sx={{ flex: 1, textAlign: 'center', pt: 6 }}>
                <Typography variant='h6'>Download Package</Typography>
            </DialogTitle>
            <DialogContent>
                <DialogContentText sx={{ textAlign: 'center', px: 12, pb: 1 }}>
                    Your package is out of date. Do you want to regenerate another?
                </DialogContentText>
            </DialogContent>
            <DialogContent sx={{ display: 'flex', gap: 2, px: 10, justifyContent: 'center' }}>
                <Button
                    onClick={props.onDownloadOutdatedClick}
                    variant='outline'>
                    Download anyway
                </Button>
                <Button
                    onClick={props.onGenerateLoanPackageClick}>
                    Build and download
                </Button>
            </DialogContent>
        </DialogContainer>}
        {props.isCheckingForExport && <Dialog
            open={props.isCheckingForExport}>
            <Dialog.Content className='bg-transparent items-center sm:max-w-fit'>
                <LoadingBox
                    title="Checking your data for export"
                    hint="This will take a moment.." />
            </Dialog.Content>
        </Dialog>}
        {props.openDialog === "NO_PRIMARY_REAL_ESTATE_COLLATERAL_ADDRESS" && <Dialog
            onOpenChange={open => !open && props.onCloseVenturesDialog()}
            open>
            <Dialog.Content className='h-full sm:h-[426px] sm:max-w-2xl lg:max-w-3xl overflow-hidden'>
                <VenturesNoPrimaryRealEstateAddress
                    onAfterAddressAdd={props.onAfterAddRealEstateAddress}
                    loanId={props.loan.id}
                    collateral={props.primaryCollateralMissingAddress} />
            </Dialog.Content>
        </Dialog>}
        {props.openDialog === "PRIMARY_NON_REAL_ESTATE_COLLATERAL_NO_POC_ADDRESS" && <Dialog
            onOpenChange={open => !open && props.onCloseVenturesDialog()}
            open>
            <Dialog.Content className='h-full sm:h-[426px] sm:max-w-2xl lg:max-w-3xl overflow-hidden'>
                <VenturesNoPocAddress
                    loanId={props.loan.id}
                    onAfterAddressAdd={props.onAfterAddRealEstateAddress}
                    pocEntity={props.primaryPOCAddressCollateral}
                    collateralName={props.collateralName} />
            </Dialog.Content>
        </Dialog>}
        {props.openDialog === "FIELDS_MAPPER" && <DialogDrawer
            onOpenChange={open => !open && props.onCloseVenturesDialog()}
            open={props.openDialog === "FIELDS_MAPPER"}>
            <DialogDrawer.Content className='max-h-[90%] sm:max-w-3xl overflow-hidden'>
                <VenturesDataChangedMapper
                    onConfirm={props.onConfirmVenturesDataChange}
                    onCancel={props.onCloseVenturesDialog}
                    items={props.venturesDataChangedItems} />
            </DialogDrawer.Content>
        </DialogDrawer>}
        {props.openDialog === "NO_POC_NO_ENTITIES_ALERT" && <ActionAlertDialog
            ariaLabel="No POC or Entities Found"
            message="You need to add a Primary Operating Company (POC) to continue"
            onOpenChange={open => !open && props.onCloseVenturesDialog()}
            open={props.openDialog === "NO_POC_NO_ENTITIES_ALERT"}
            confirmButtonText="Add POC"
            onConfirm={props.onAddPocClick}
        />}
        {props.openDialog === "NO_COLLATERAL_ALERT" && <ActionAlertDialog
            ariaLabel="No Collateral Found"
            message="There’s no collateral on this loan. Add a primary collateral with an address to continue"
            onOpenChange={open => !open && props.onCloseVenturesDialog()}
            open={props.openDialog === "NO_COLLATERAL_ALERT"}
            confirmButtonText="Add Collateral"
            onConfirm={props.onAddCollateral}
        />}
        {/* {props.openDialog === "NO_PRIMARY_REAL_ESTATE_COLLATERAL_ADDRESS" && <ActionAlertDialog
            ariaLabel="No Address In Collateral"
            message={`There’s no address on your primary collateral “${props.primaryCollateralMissingAddress?.sherpaEntity?.name}”. Add address to continue`}
            onOpenChange={open => !open && props.onCloseVenturesDialog()}
            open
            confirmButtonText="Add Address"
            onConfirm={props.onAddCollateralAddress}
        />} */}
        {props.openDialog === "CONFIRM_FIRST_EXPORT_POC" && <ActionAlertDialog
            ariaLabel="Confirm POC export to Ventures"
            message={<><Text as="div">
                The <Text weight="medium" as="span">Primary Operating Company</Text> is {props.pocEntity?.sherpaEntity?.name}.
            </Text> This can only be set once prior to exporting to Ventures. Please confirm to continue
            </>}
            onOpenChange={open => !open && props.onCloseVenturesDialog()}
            open={props.openDialog === "CONFIRM_FIRST_EXPORT_POC"}
            onConfirm={props.onConfirmPOCExport}
            variant="danger"
        />}
        {props.openDialog === "CONFIRM_POC_CHANGED" && <ActionAlertDialog
            ariaLabel="Confirm POC CHANGE export to Ventures"
            message={<>The <Text weight="medium" as="span">Primary Operating Company</Text> has changed to {props.pocEntity?.sherpaEntity?.name} since last export to Ventures. This will create a <Text weight="medium" as="span">NEW LOAN</Text> in Ventures. Please confirm to continue.</>}
            onOpenChange={open => !open && props.onCloseVenturesDialog()}
            open={props.openDialog === "CONFIRM_POC_CHANGED"}
            onConfirm={props.onConfirmPOCChangeExport}
            variant="danger"
        />}
        {props.openDialog === "ENTITIES_FORM" && <DialogDrawer
            onOpenChange={open => !open && props.onCloseVenturesDialog()}
            open={props.openDialog === "ENTITIES_FORM"}>
            <DialogDrawer.Content className='sm:max-w-3xl overflow-hidden'>
                <VenturesEntitiesSetup
                    onCancel={props.onCloseVenturesDialog}
                    onSuccess={props.onSetupEntitiesComplete}
                    loanId={props.loan.id}
                    entities={props.venturesEntities} />
            </DialogDrawer.Content>
        </DialogDrawer>}
        {props.openDialog === "POC_PICKER" && <DialogDrawer
            onOpenChange={open => !open && props.onCloseVenturesDialog()}
            open={props.openDialog === "POC_PICKER"}>
            <DialogDrawer.Content
                onOpenAutoFocus={(event) => event.preventDefault()}
                className='sm:max-w-lg overflow-hidden'>
                <VenturesPickPOC
                    onCancel={props.onCloseVenturesDialog}
                    onSuccess={props.onSetupEntitiesComplete}
                    loanId={props.loan.id}
                    entities={props.venturesEntities} />
            </DialogDrawer.Content>
        </DialogDrawer>}
    </>
}