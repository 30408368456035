import * as Yup from 'yup';

export const VenturesNoPOCAddressValidation = Yup
    .object()
    .shape({
        companyAddress: Yup.object().shape({
            streetAddressLine1: Yup.string().required('Required'),
            citySuburb: Yup.string().required('Required'),
            stateProvinceRegion: Yup.string().required('Required'),
            postalCode: Yup.string(),
            country: Yup.string(),
        }),
    })
