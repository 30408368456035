import { EntityMlaFields } from "src/types/loan"

export const calculateEntityTotalCost = ({
    repairCost,
    constructionCost,
    purchasePrice,
    totalCompletionCost,
    lotPurchasePrice,
}: EntityMlaFields): number => {
    if (totalCompletionCost) {
        return getIntegerValue(totalCompletionCost)
    }

    return getIntegerValue(repairCost) +
        getIntegerValue(purchasePrice) +
        getIntegerValue(constructionCost) +
        getIntegerValue(lotPurchasePrice)
}

const getIntegerValue = (value: string) => {
    if (!value) return 0
    return value ? parseInt(String(value).replace(/,/g, '')) : 0
}

