import Accordion from "components/dist/atoms/Accordion";
import Badge from "components/dist/atoms/Badge";
import Icon from "components/dist/atoms/Icon";
import RadioGroup from "components/dist/atoms/RadioGroup";
import Separator from "components/dist/atoms/Separator";
import Stack from "components/dist/atoms/Stack";
import Text from "components/dist/atoms/Text";
import { LoanEntityDto } from "src/backend";
import { getEntityIcon } from "src/utils/entity/get-entity-icon";
import { getEntityLabels } from "src/utils/entity/get-entity-labels";

interface VenturesPickPOCListItemProps {
    items: LoanEntityDto[],
    title: string,
}

const MAX_LABELS = 3;


export const VenturesPickPOCListItem = (props: VenturesPickPOCListItemProps) => {
    return <Accordion
        key={props.title}
        className="px-4"
        defaultValue={["Companies", 'Trusts', "Collateral offered"]}
        type="multiple">
        <Accordion.Item value={props.title}>
            <Accordion.Trigger className='text-sm font-normal cursor-pointer' asChild>
                <Stack space="sm" row>
                    <Badge
                        className='w-6 h-6 aspect-square shrink-0 items-center justify-center'
                        variant="secondary">
                        {props.items.length}
                    </Badge>
                    {props.title}
                </Stack>
            </Accordion.Trigger>
            <Separator />
            <Accordion.Content>
                {props.items.map((entity) => {
                    // only display labels for company / trust
                    const labels = (["COMPANY", "TRUST"].includes(entity.sherpaEntity.type))
                        ? getEntityLabels({ entity, short: true })
                        : []
                    const totalLabels = labels.length;

                    return (<Stack
                        row
                        key={entity.id}
                        space="sm"
                        className='py-3'>
                        <Stack space="sm" row className='flex-1 items-center'>
                            <RadioGroup.Item
                                size="md"
                                variant="secondary"
                                value={entity.id} id={entity.id} />
                            <Icon name={getEntityIcon({ entity })} className='shrink-0' width={20} height={20} strokeWidth={1.5} />
                            <Text size="sm" weight="medium" className="shrink-0">{entity.sherpaEntity.name}</Text>
                            {labels
                                .filter((_, index) => index < MAX_LABELS)
                                .map(label => <Badge
                                    key={label}
                                    variant="secondary"
                                    className='capitalize'>
                                    {label}
                                </Badge>)}
                            {totalLabels > MAX_LABELS && <Badge
                                variant="secondary"
                                className='capitalize'>
                                +{totalLabels - MAX_LABELS}
                            </Badge>}
                        </Stack>
                    </Stack>)
                })}
            </Accordion.Content>
        </Accordion.Item>
    </Accordion>
}