import ProgressBar from 'components/dist/atoms/ProgressBar';
import { Loan } from "src/types/loan";

interface LoanProgressBarProps {
    loan: Loan;
    showLegend?: boolean;
}
export const LoanProgressBar = (props: LoanProgressBarProps) => {
    const progressBarItems = [
        {
            title: "Submitted",
            color: "#FAC62C",
            className: "",
            legendOrder: 3,
            value: props.loan.percentageAnswered,
        },

        {
            title: "Loan Progress",
            color: "#8091DD",
            className: "",
            value: props.loan.loanProgress,
            legendOrder: 1
        },
        {
            title: "Accepted",
            color: "#7ACC9B",
            className: "",
            legendOrder: 2,
            value: props.loan.percentageApproved
        },
        {
            title: "Open",
            color: "#D3D3D7",
            className: "",
            legendOrder: 4,
            value: 100 - props.loan.percentageAnswered,
            excludeFromProgress: true
        },
    ]

    return (<ProgressBar
        className='flex flex-col w-full gap-3'
        showSeparator
        showLegend={props.showLegend}
        progressBackGroundColor='bg-black-20'
        items={progressBarItems}
    />);
};