import { IconProps } from "components/dist/atoms/Icon";
import type { LoanEntityDto } from "src/backend";

export const getEntityIcon = (args: { entity: Partial<LoanEntityDto> }): IconProps['name'] => {

    if (args.entity.sherpaEntity.type === 'INDIVIDUAL') {
        return "User";
    } else if (args.entity.sherpaEntity.type === 'COMPANY' ||
        args.entity.sherpaEntity.type === 'TRUST') {
        return "Building";
    } else if (args.entity.sherpaEntity.type === 'ASSET') {
        return "HomeSale";
    }

    return "Building";
}