import { ChipProps } from "@mui/material/Chip";
import { JestTestId } from "src/constants/tests";

import { LabelChipStyles } from "./label-chip.styles"

interface LabelChipProps extends ChipProps {
    button?: boolean;
    onClick?: (event: React.MouseEvent<any>) => void;

}

export const LabelChip = (props: LabelChipProps) => {
    const { button, size = "small", ...rest } = props;
    return <LabelChipStyles.Chip
        role={button ? "button" : 'presentation'}
        color={props.color}
        data-testid={JestTestId.LabelChipContainer}
        disabled={props.disabled}
        size={size}
        label={props.label}
        icon={props.icon}
        {
        ...button && { component: "button", onClick: props.onClick }
        }
        {...rest} />
}
